/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "amplifyIdentityBrokerApi",
            "endpoint": "https://ip5jdgb8y1.execute-api.us-east-1.amazonaws.com/ibpreprod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:64dafb9d-6cb6-4d2b-84d6-07e628bd0137",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_a25vv3ww9",
    "aws_user_pools_web_client_id": "5b32ikhg6itjjvppqe23fr5h50",
    "oauth": {
        "domain": "wave-identity-broker-ibpreprod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d2oi7alipcf4h6.cloudfront.net",
        "redirectSignOut": "https://d2oi7alipcf4h6.cloudfront.net/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "broker--hostingbucket-ibpreprod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2oi7alipcf4h6.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "amplifyIdentityBrokerClients-ibpreprod",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerCodes-ibpreprod",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerInstance-ibpreprod",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerRegistrationCodes-ibpreprod",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerUser-ibpreprod",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerUserInstance-ibpreprod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
